import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../../images/ahead-hash-icon-white.png";

const SideBar = () => {
  const { pathname } = useLocation();

  return (
    <aside className="main-sidebar sidebar-dark-primary  elevation-4">
      <Link
        to="/"
        className={
          window.location.pathname == "/"
            ? "brand-link bg-primary mot"
            : "brand-link motLightText"
        }
      >
        <img
          src={logo}
          alt="Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span
          className={`brand-text font-weight-light  ${window.location.pathname == "/" ? "motText" : ""
            }`}
        >
          {process.env.REACT_APP_WEBSITE_NAME} CMS
        </span>
      </Link>

      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item ">
              <Link
                to="/admins"
                className={`nav-link ${/^(\/)admins(\/)|^(\/)admin/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Admin Users</p>
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to="/banners"
                className={`nav-link ${/^(\/)banners(\/)|^(\/)banners/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Banners</p>
              </Link>
            </li>
            <li
              className={`nav-item ${/^(\/)fossils-collection(\/)*|^(\/)fossils-saleable(\/)*/i.test(
                pathname
              )
                ? "menu-is-opening menu-open "
                : "motLightText"
                }`}
            >
              <a
                className={`nav-link ${/^(\/)fossils-collection(\/)*|^(\/)fossils-saleable(\/)*/i.test(
                  pathname
                )
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-folder" />
                <p>
                  Fossils
                  <i className="fas fa-angle-left right" />
                  {/* <span className="badge badge-info right">6</span> */}
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item ">
                  <Link
                    to="/fossils-collection"
                    className={`nav-link ${/^(\/)fossils-collection(\/)|^(\/)fossils-collection/i.test(pathname)
                      ? "active mot"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Fossils Collection</p>
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    to="/fossils-saleable"
                    className={`nav-link ${/^(\/)fossils-saleable(\/)|^(\/)fossils-saleable/i.test(pathname)
                      ? "active mot"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Fossils Saleable</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item ">
              <Link
                to="/fossil-sites"
                className={`nav-link ${/^(\/)fossil-sites(\/)|^(\/)fossil-sites/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Fossil Sites</p>
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to="/fossil-sub-categories"
                className={`nav-link ${/^(\/)fossil-sub-categories(\/)|^(\/)fossil-sub-categories/i.test(
                  pathname
                )
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Fossil Sub Categories</p>
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to="/buyers"
                className={`nav-link ${/^(\/)buyers(\/)|^(\/)buyers/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Buyers</p>
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to="/carts"
                className={`nav-link ${/^(\/)carts(\/)|^(\/)carts/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Carts</p>
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to="/orders"
                className={`nav-link ${/^(\/)orders(\/)|^(\/)orders/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Orders</p>
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to="/media-gallery-albums"
                className={`nav-link ${/^(\/)media-gallery-albums(\/)|^(\/)media-gallery-albums/i.test(
                  pathname
                )
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Media Gallery</p>
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to="/publications"
                className={`nav-link ${/^(\/)publications(\/)|^(\/)publications/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Publications</p>
              </Link>
            </li>{" "}
            <li className="nav-item ">
              <Link
                to="/press-releases"
                className={`nav-link ${/^(\/)press-releases(\/)|^(\/)press-releases/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Press Releases</p>
              </Link>
            </li>{" "}
            <li className="nav-item ">
              <Link
                to="/news-events"
                className={`nav-link ${/^(\/)news-events(\/)|^(\/)news-events/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>News and Events</p>
              </Link>
            </li>{" "}
            <li className="nav-item ">
              <Link
                to="/payments"
                className={`nav-link ${/^(\/)payments(\/)|^(\/)payments/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Payments</p>
              </Link>
            </li>
            <li className="nav-item ">
              <Link
                to="/payment-requests"
                className={`nav-link ${/^(\/)payment-requests(\/)|^(\/)payment-requests/i.test(pathname)
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Payment Requests</p>
              </Link>
            </li>
            <li
              className={`nav-item ${/^(\/)contact-us(\/)*|^(\/)subscribers(\/)*|^(\/)testimonials(\/)*/i.test(
                pathname
              )
                ? "menu-is-opening menu-open "
                : "motLightText"
                }`}
            >
              <a
                className={`nav-link ${/^(\/)contact-us(\/)*|^(\/)subscribers(\/)*|^(\/)testimonials(\/)*/i.test(
                  pathname
                )
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-edit" />
                <p>
                  Online Forms
                  <i className="fas fa-angle-left right" />
                  {/* <span className="badge badge-info right">6</span> */}
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/contact-us"
                    className={`nav-link ${/^(\/)contact-us(\/)|^(\/)contact-us/i.test(pathname)
                      ? "active motLight"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Contact Us</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/subscribers"
                    className={`nav-link ${/^(\/)subscribers(\/)|^(\/)subscribers/i.test(pathname)
                      ? "active motLight"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Newsletter</p>
                  </Link>
                </li>

                <li className="nav-item ">
                  <Link
                    to="/testimonials"
                    className={`nav-link ${/^(\/)testimonials(\/)|^(\/)testimonials/i.test(pathname)
                      ? "active mot"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Testimonials</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item ${/^(\/)settings(\/)*|^(\/)lookups(\/)*|^(\/)pages(\/)*|^(\/)page-gallery(\/)*|^(\/)countries(\/)*|^(\/)nationalities(\/)*|^(\/)useful-links(\/)*/i.test(
                pathname
              )
                ? "menu-is-opening menu-open "
                : "motLightText"
                }`}
            >
              <a
                className={`nav-link ${/^(\/)settings(\/)*|^(\/)lookups(\/)*|^(\/)pages(\/)*|^(\/)countries(\/)*|^(\/)nationalities(\/)*|^(\/)useful-links(\/)*|^(\/)external-links(\/)*/i.test(
                  pathname
                )
                  ? "active mot"
                  : "motLightText"
                  }`}
              >
                <i className="nav-icon fas fa-cog" />
                <p>
                  Settings & Content
                  <i className="fas fa-angle-left right" />
                  {/* <span className="badge badge-info right">6</span> */}
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/settings"
                    className={`nav-link ${/^(\/)settings(\/)*/i.test(pathname)
                      ? "active motLight"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Settings</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/lookups"
                    className={`nav-link ${/^(\/)lookups(\/)*/i.test(pathname)
                      ? "active motLight"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Lookup Values</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/pages"
                    className={`nav-link ${/^(\/)pages(\/)*|^(\/)page-gallery(\/)*/i.test(pathname)
                      ? "active motLight"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Pages & Content</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/external-links"
                    className={`nav-link ${/^(\/)external-links(\/)*|^(\/)external-links(\/)*/i.test(pathname)
                      ? "active motLight"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>External Links</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/useful-links"
                    className={`nav-link ${/^(\/)useful-links(\/)*/i.test(pathname)
                      ? "active motLight"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Useful Links</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/countries"
                    className={`nav-link ${/^(\/)countries(\/)*/i.test(pathname)
                      ? "active motLight"
                      : "motLightText"
                      }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Countries</p>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item ">
              <a
                href="https://cms.memoryoftime.com/user-manual/"
                target="_blank"
                className="nav-link motLightText"
              >
                <i className="nav-icon fas fa-file" />
                <p>User Manual</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
