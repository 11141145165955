import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../containers/Home";
import Login from "../containers/Login";
import PrivateRoute from "../HOC/PrivateRoute";

import NotFound from "./../containers/NotFound";
import SettingsListing from "../components/modules/settings/SettingsListing";
import LookupListing from "../components/modules/lookups/LookupListing";
import PagesListing from "../components/modules/pages/PagesListing";
import PageContentUpdate from "../components/modules/pages/PageContentUpdate";

import AdminListing from "../components/modules/admins/AdminListing";
import AdminCreate from "../components/modules/admins/AdminCreate";
import AdminView from "../components/modules/admins/AdminView";
import AdminUpdate from "../components/modules/admins/AdminUpdate";
import AdminChangePassword from "../components/modules/admins/AdminChangePassword";

import ContactUsListing from "../components/modules/contactUs/ContactUsListing";
import ContactUsView from "../components/modules/contactUs/ContactUsView";

import UsefulLinkListing from "../components/modules/usefulLinks/UsefulLinkListing";
import UsefulLinkView from "../components/modules/usefulLinks/UsefulLinkView";
import UsefulLinkCreate from "../components/modules/usefulLinks/UsefulLinkCreate";
import UsefulLinkUpdate from "../components/modules/usefulLinks/UsefulLinkUpdate";

import NationalityListing from "../components/modules/nationalities/NationalityListing";

import CountryListing from "../components/modules/countries/CountryListing";
import CountryCreate from "../components/modules/countries/CountryCreate";
import CountryView from "../components/modules/countries/CountryView";
import CountryUpdate from "../components/modules/countries/CountryUpdate";

import PaymentListing from "../components/modules/payments/PaymentListing";
import PaymentView from "../components/modules/payments/PaymentView";
import PaymentUpdate from "../components/modules/payments/PaymentUpdate";

import NationalityCreate from "../components/modules/nationalities/NationalityCreate";
import NationalityUpdate from "../components/modules/nationalities/NationalityUpdate";
import NationalityView from "../components/modules/nationalities/NationalityView";
import SubscribersListing from "../components/modules/subscribers/SubscribersListing";
import SubscribersView from "../components/modules/subscribers/SubscribersView";
import TestimonialListing from "../components/modules/testimonial/TestimonialListing";
import TestimonialView from "../components/modules/testimonial/TestimonialView";
import TestimonialUpdate from "../components/modules/testimonial/TestimonialUpdate";
import TestimonialCreate from "../components/modules/testimonial/TestimonialCreate";
import PublicationsListing from "../components/modules/publications/PublicationsListing";
import PublicationsCreate from "../components/modules/publications/PublicationsCreate";
import PublicationsView from "../components/modules/publications/PublicationsView";
import PublicationsUpdate from "../components/modules/publications/PublicationsUpdate";
import PressReleasesListing from "../components/modules/pressReleases/PressReleasesListing";
import PressReleasesView from "../components/modules/pressReleases/PressReleasesView";
import PressReleasesCreate from "../components/modules/pressReleases/PressReleasesCreate";
import PressReleasesUpdate from "../components/modules/pressReleases/PressReleasesUpdate";
import NewsEventsCreate from "../components/modules/newsEvents/NewsEventsCreate";
import NewsEventsListing from "../components/modules/newsEvents/NewsEventsListing";
import NewsEventsView from "../components/modules/newsEvents/NewsEventsView";
import NewsEventsUpdate from "../components/modules/newsEvents/NewsEventsUpdate";
import MediaGalleryListing from "../components/modules/mediaGallery/MediaGalleryListing";
import MediaGalleryView from "../components/modules/mediaGallery/MediaGalleryView";
import MediaGalleryUpdate from "../components/modules/mediaGallery/MediaGalleryUpdate";
import MediaGalleryCreate from "../components/modules/mediaGallery/MediaGalleryCreate";
import MediaGalleryDetails from "../components/modules/mediaGallery/MediaGalleryDetails";
import CartListing from "../components/modules/cart/CartListing";
import CartView from "../components/modules/cart/CartView";
import OrderView from "../components/modules/orders/OrderView";
import OrdersListing from "../components/modules/orders/OrdersListing";
import BuyersListing from "../components/modules/buyers/BuyersListing";
import BuyersView from "../components/modules/buyers/BuyersView";
import FossilsListing from "../components/modules/fossils/FossilsListing";
import FossilsView from "../components/modules/fossils/FossilsView";
import FossilsCreate from "../components/modules/fossils/FossilsCreate";
import FossilsUpdate from "../components/modules/fossils/FossilsUpdate";
import CollectionDetails from "../components/modules/fossilsCollection/partials/details/CollectionDetails";
import SaleableDetails from "../components/modules/fossilsSaleable/partials/details/SaleableDetails";
import SubCategoryListing from "../components/modules/fossilsSubCategories/SubCategoryListing";
import SubCategoryCreate from "../components/modules/fossilsSubCategories/SubCategoryCreate";
import SubCategoryUpdate from "../components/modules/fossilsSubCategories/SubCategoryUpdate";
import AddCollectionDetails from "../components/modules/fossils/partials/details/AddCollectionDetails";
import AddSaleableDetails from "../components/modules/fossilsSaleable/partials/details/AddSaleableDetails";
import BannersListing from "../components/modules/banners/BannersListing";
import BannersView from "../components/modules/banners/BannersView";
import BannersCreate from "../components/modules/banners/BannersCreate";
import BannersUpdate from "../components/modules/banners/BannersUpdate";
import FossilsSiteListing from "../components/modules/fossilsSites/FossilsSiteListing";
import FossilsSiteView from "../components/modules/fossilsSites/FossilsSiteView";
import FossilsSiteCreate from "../components/modules/fossilsSites/FossilsSiteCreate";
import FossilsSiteUpdate from "../components/modules/fossilsSites/FossilsSiteUpdate";
import PageGalleryView from "../components/modules/pageGallery/PageGalleryView";
import FossilsSaleableListing from "../components/modules/fossilsSaleable/FossilsSaleableListing";
import FossilsSaleableView from "../components/modules/fossilsSaleable/FossilsSaleableView";
import FossilsSaleableCreate from "../components/modules/fossilsSaleable/FossilsSaleableCreate";
import FossilsSaleableUpdate from "../components/modules/fossilsSaleable/FossilsSaleableUpdate";
import FossilsCollectionListing from "../components/modules/fossilsCollection/FossilsCollectionListing";
import FossilsCollectionView from "../components/modules/fossilsCollection/FossilsCollectionView";
import FossilsCollectionCreate from "../components/modules/fossilsCollection/FossilsCollectionCreate";
import FossilsCollectionUpdate from "../components/modules/fossilsCollection/FossilsCollectionUpdate";
import PaymentRequestListing from "../components/modules/paymentRequests/PaymentRequestListing";
import PaymentRequestView from "../components/modules/paymentRequests/PaymentRequestView";

import ExternalLinkListing from "../components/modules/externalLinks/ExternalLinkListing";
import ExternalLinkCreate from "../components/modules/externalLinks/ExternalLinkCreate";
import ExternalLinkUpdate from "../components/modules/externalLinks/ExternalLinkUpdate";

const ApplicationRoutes = () => {
  useEffect(() => {
    // console.log("ApplicationRoutes");
  }, []);

  return (
    <div>
      {/* basename="/nodeApp/cms" */}
      <Router >
        <Routes>
          {/* element={userAuthState.isLoggedIn ? <Navigate to="/" /> : <Login />} */}
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/"
            forceRefresh={true}
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/settings"
            element={
              <PrivateRoute>
                <SettingsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/lookups"
            element={
              <PrivateRoute>
                <LookupListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/pages"
            element={
              <PrivateRoute>
                <PagesListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/pages/update/:id"
            element={
              <PrivateRoute>
                <PageContentUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/admins"
            element={
              <PrivateRoute>
                <AdminListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admins/create"
            element={
              <PrivateRoute>
                <AdminCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admins/:id"
            element={
              <PrivateRoute>
                <AdminView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admins/update/:id"
            element={
              <PrivateRoute>
                <AdminUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/admins/change-password/:id"
            element={
              <PrivateRoute>
                <AdminChangePassword />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/contact-us"
            element={
              <PrivateRoute>
                <ContactUsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/contact-us/:id"
            element={
              <PrivateRoute>
                <ContactUsView />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/subscribers"
            element={
              <PrivateRoute>
                <SubscribersListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/subscribers/:id"
            element={
              <PrivateRoute>
                <SubscribersView />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/useful-links"
            element={
              <PrivateRoute>
                <UsefulLinkListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/useful-links/create"
            element={
              <PrivateRoute>
                <UsefulLinkCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/useful-links/:id"
            element={
              <PrivateRoute>
                <UsefulLinkView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/useful-links/update/:id"
            element={
              <PrivateRoute>
                <UsefulLinkUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/nationalities"
            element={
              <PrivateRoute>
                <NationalityListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/nationalities/create"
            element={
              <PrivateRoute>
                <NationalityCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/nationalities/:id"
            element={
              <PrivateRoute>
                <NationalityView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/nationalities/update/:id"
            element={
              <PrivateRoute>
                <NationalityUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/countries"
            element={
              <PrivateRoute>
                <CountryListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/countries/create"
            element={
              <PrivateRoute>
                <CountryCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/countries/:id"
            element={
              <PrivateRoute>
                <CountryView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/countries/update/:id"
            element={
              <PrivateRoute>
                <CountryUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/page-gallery/:id"
            element={
              <PrivateRoute>
                <PageGalleryView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/media-gallery-albums"
            element={
              <PrivateRoute>
                <MediaGalleryListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/media-gallery-albums/create"
            element={
              <PrivateRoute>
                <MediaGalleryCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/media-gallery-albums/:id"
            element={
              <PrivateRoute>
                <MediaGalleryView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/media-gallery-albums/update/:id"
            element={
              <PrivateRoute>
                <MediaGalleryUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/media-gallery-albums/picture-details/:id"
            element={
              <PrivateRoute>
                <MediaGalleryDetails />
              </PrivateRoute>
            }
          />
          {/* <Route exact path="/gallery-picture-comments/:id" element={<PrivateRoute><GalleryPictureCommentsListing /></PrivateRoute>} />
                    <Route exact path="/gallery-picture-likes/:id" element={<PrivateRoute><GalleryPictureLikesListing /></PrivateRoute>} />  */}

          <Route
            exact
            path="/buyers"
            element={
              <PrivateRoute>
                <BuyersListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/buyers/:id"
            element={
              <PrivateRoute>
                <BuyersView />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/carts"
            element={
              <PrivateRoute>
                <CartListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/carts/:id"
            element={
              <PrivateRoute>
                <CartView />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/orders"
            element={
              <PrivateRoute>
                <OrdersListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/orders/:id"
            element={
              <PrivateRoute>
                <OrderView />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/payments"
            element={
              <PrivateRoute>
                <PaymentListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/payments/:id"
            element={
              <PrivateRoute>
                <PaymentView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/payments/update/:id"
            element={
              <PrivateRoute>
                <PaymentUpdate />
              </PrivateRoute>
            }
          />


          <Route
            exact
            path="/payment-requests"
            element={
              <PrivateRoute>
                <PaymentRequestListing />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/payment-requests/:id"
            element={
              <PrivateRoute>
                <PaymentRequestView />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/fossil-sub-categories"
            element={
              <PrivateRoute>
                <SubCategoryListing />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/fossil-sub-categories/create"
            element={
              <PrivateRoute>
                <SubCategoryCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossil-sub-categories/update/:id"
            element={
              <PrivateRoute>
                <SubCategoryUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossil-sites"
            element={
              <PrivateRoute>
                <FossilsSiteListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossil-sites/:id"
            element={
              <PrivateRoute>
                <FossilsSiteView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossil-sites/create"
            element={
              <PrivateRoute>
                <FossilsSiteCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossil-sites/update/:id"
            element={
              <PrivateRoute>
                <FossilsSiteUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils-saleable"
            element={
              <PrivateRoute>
                <FossilsSaleableListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils-saleable/:id"
            element={
              <PrivateRoute>
                <FossilsSaleableView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils-saleable/create"
            element={
              <PrivateRoute>
                <FossilsSaleableCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils-saleable/update/:id"
            element={
              <PrivateRoute>
                <FossilsSaleableUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils-collection"
            element={
              <PrivateRoute>
                <FossilsCollectionListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils-collection/:id"
            element={
              <PrivateRoute>
                <FossilsCollectionView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils-collection/create"
            element={
              <PrivateRoute>
                <FossilsCollectionCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils-collection/update/:id"
            element={
              <PrivateRoute>
                <FossilsCollectionUpdate />
              </PrivateRoute>
            }
          />
          {/* <Route
            exact
            path="/fossils/add-collection-attributes/:id"
            element={
              <PrivateRoute>
                <AddCollectionDetails />
              </PrivateRoute>
            }
          /> */}
          <Route
            exact
            path="/fossils-collection/collection-attributes/:id"
            element={
              <PrivateRoute>
                <CollectionDetails />
              </PrivateRoute>
            }
          />
          {/* <Route
            exact
            path="/fossils"
            element={
              <PrivateRoute>
                <FossilsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils/:id"
            element={
              <PrivateRoute>
                <FossilsView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils/create"
            element={
              <PrivateRoute>
                <FossilsCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils/update/:id"
            element={
              <PrivateRoute>
                <FossilsUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils/add-collection-attributes/:id"
            element={
              <PrivateRoute>
                <AddCollectionDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils/collection-attributes/:id"
            element={
              <PrivateRoute>
                <CollectionDetails />
              </PrivateRoute>
            }
          /> */}
          <Route
            exact
            path="/fossils-saleable/saleable-attributes/:id"
            element={
              <PrivateRoute>
                <SaleableDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/fossils-saleable/add-saleable-attributes/:id"
            element={
              <PrivateRoute>
                <AddSaleableDetails />
              </PrivateRoute>
            }
          />



          <Route
            exact
            path="/external-links"
            element={
              <PrivateRoute>
                <ExternalLinkListing />
              </PrivateRoute>
            }
          />

<Route
            exact
            path="/external-links/create"
            element={
              <PrivateRoute>
                <ExternalLinkCreate />
              </PrivateRoute>
            }
          />

<Route
            exact
            path="/external-links/update/:id"
            element={
              <PrivateRoute>
                <ExternalLinkUpdate />
              </PrivateRoute>
            }
          />





          <Route
            exact
            path="/publications"
            element={
              <PrivateRoute>
                <PublicationsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/publications/:id"
            element={
              <PrivateRoute>
                <PublicationsView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/publications/create"
            element={
              <PrivateRoute>
                <PublicationsCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/publications/update/:id"
            element={
              <PrivateRoute>
                <PublicationsUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/banners"
            element={
              <PrivateRoute>
                <BannersListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/banners/:id"
            element={
              <PrivateRoute>
                <BannersView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/banners/create"
            element={
              <PrivateRoute>
                <BannersCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/banners/update/:id"
            element={
              <PrivateRoute>
                <BannersUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/press-releases"
            element={
              <PrivateRoute>
                <PressReleasesListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/press-releases/:id"
            element={
              <PrivateRoute>
                <PressReleasesView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/press-releases/create"
            element={
              <PrivateRoute>
                <PressReleasesCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/press-releases/update/:id"
            element={
              <PrivateRoute>
                <PressReleasesUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/news-events"
            element={
              <PrivateRoute>
                <NewsEventsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/news-events/:id"
            element={
              <PrivateRoute>
                <NewsEventsView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/news-events/create"
            element={
              <PrivateRoute>
                <NewsEventsCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/news-events/update/:id"
            element={
              <PrivateRoute>
                <NewsEventsUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/testimonials"
            element={
              <PrivateRoute>
                <TestimonialListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/testimonials/:id"
            element={
              <PrivateRoute>
                <TestimonialView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/testimonials/create"
            element={
              <PrivateRoute>
                <TestimonialCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/testimonials/update/:id"
            element={
              <PrivateRoute>
                <TestimonialUpdate />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default ApplicationRoutes;
