import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as yup from "yup"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'External Links';
moduleConfig.singleItemTitle = 'External Link';
moduleConfig.url = 'external-links';

moduleConfig.fields = [
    {
        displayName: "ID",
        fieldName: "_id",
        type: "text",
        control: "hidden",
    },
    {
        displayName: "Title",
        fieldName: "title",
        type: "text",
        control: "input",
        required: true,
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Link",
        fieldName: "link",
        required: true,
        type: "url",
        control: "input",
    },
    {
        displayName: "Sort Order",
        fieldName: "sortOrder",
        type: "number",
        control: "input",
    },
    {
        displayName: "Status",
        fieldName: "status",
        control: "select",
        options: [
            { label: "Enabled", value: 1 },
            { label: "Disabled", value: 0 },
        ],
        trueValue: "Enabled",
        falseValue: "Disabled",
        type: "boolean",
        showOnListing: true,
        sortable: true,
    },


];
moduleConfig.initialValues = {
    title: "",
    link: "",
    sortOrder: 999,
    status: 1
}
moduleConfig.validationSchema = yup.object({
    title: yup.string().required("Required").max(100),
    link: yup.string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter a valid URL!'
        )
        .required('Required'),


    sortOrder: yup.number().required("Required"),
    status: yup.number().required("Required"),
})

moduleConfig.hasFile = false

export default moduleConfig;